import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { LogoutOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useAuth0 } from '@auth0/auth0-react';
import { theme } from '../../styles/theme';
import { headerData } from './HeaderData';

const logo = headerData.header.logo;

const Header = () => {
  const { logout, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  return (
    <HeaderContainer>
      <Logo onClick={() => navigate('/home')}>
        <Typography variant="h5" component="h1">{logo}</Typography>
      </Logo>
      <RightPanel>
        <HelpLink to="/help-support">Help & Support</HelpLink>
        {isAuthenticated && (
          <>
            <ProfileIcon onClick={toggleUserDropdown}>
              {String(user?.name)[0]}
            </ProfileIcon>
            {userDropdownOpen && (
              <UserDropdown>
                <div>
                  <b>{user?.name}</b>
                </div>
                <div>{user?.email}</div>
                <Button onClick={() => logout()} variant="contained">
                  <LogoutOutlined /> Log Out
                </Button>
              </UserDropdown>
            )}
          </>
        )}
      </RightPanel>
    </HeaderContainer>
  );
};

// Styled Components

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const Logo = styled.div`
  font-size: 1.5em;
  cursor: pointer;
`;

const RightPanel = styled.div`
  display: flex;
  align-items: center;
`;

const HelpLink = styled(Link)`
  color: #fff;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

const ProfileIcon = styled.div`
  background-color: ${theme.colors.profileBackground};
  color: ${theme.colors.white};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
`;

const UserDropdown = styled.div`
  position: absolute;
  top: 64px;
  right: 28px;
  color: black;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 14px 25px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  & div {
    margin: 1px 0px;
    text-align: center;
  }
  & button {
    margin-top: 16px;
    & svg {
      margin-right: 6px;
    }
  }
`;

export default Header;
