// Data for Dashboard page
export const dashboardData = {
  welcomeSection: {
    title: 'Welcome to SaaSPro!',
    subtitle: 'Manage your business operations, track performance, and streamline your workflows.',
    imageUrl: '/cocreate-starter-app-img-1.png',
  },
  quickLinks: [
    {
      title: 'Manage Users',
      description: 'Easily manage user roles, permissions, and access.',
      linkText: 'View Users',
      link: '/manage-users',
    },
    {
      title: 'Business Analytics',
      description: 'Track and analyze your business performance in real-time.',
      linkText: 'Go to Analytics',
      link: '/business-analytics',
    },
    {
      title: 'Subscription Management',
      description: 'Review and manage your subscriptions, billing, and payments.',
      linkText: 'Manage Subscriptions',
      link: '/subscription-management',
    },
  ],
  // Chart Data
  chartData: {
    lineChart: {
      title: 'Revenue Over Time',
      data: [
        { name: 'Week 1', revenue: 12000 },
        { name: 'Week 2', revenue: 15000 },
        { name: 'Week 3', revenue: 18000 },
        { name: 'Week 4', revenue: 21000 },
      ],
      xKey: 'name',
      yKey: 'revenue',
    },
    pieChart: {
      title: 'Subscription Types',
      data: [
        { name: 'Basic', value: 45 },
        { name: 'Pro', value: 35 },
        { name: 'Enterprise', value: 20 },
      ],
      colors: ['#0088FE', '#00C49F', '#FFBB28'],
    },
  },
  promotions: [
    {
      title: 'Free Trial Offer',
      description: 'Sign up for a 30-day free trial and experience the full range of features.',
      buttonText: 'Start Free Trial',
    },
    {
      title: 'Referral Program',
      description: 'Refer a friend and earn rewards on your next subscription.',
      buttonText: 'View Details',
    },
  ],
};
