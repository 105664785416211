import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { dashboardData } from './DashboardData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import {
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const Dashboard = () => {
  const { welcomeSection, quickLinks, promotions, chartData } = dashboardData;

  return (
    <Container>
      <WelcomeSection>
        <TextSection>
          <h2>{welcomeSection.title || 'Default Title'}</h2>
          <p>{welcomeSection.subtitle || 'Default Subtitle'}</p>
        </TextSection>
        {welcomeSection.imageUrl && (
          <ImageSection>
            <HeaderImage src={welcomeSection.imageUrl} alt="Homepage Image" />
          </ImageSection>
        )}
      </WelcomeSection>

      {/* Quick Links Carousel */}
      <QuickLinksSection>
        <h3>Quick Links</h3>
        <Swiper
          spaceBetween={20}
          slidesPerView={3}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
        >
          {quickLinks.map((link, index) => (
            <SwiperSlide key={index}>
              <LinkCard>
                <h4>{link.title}</h4>
                <p>{link.description}</p>
                <StyledLink to={link.link}>{link.linkText}</StyledLink>
              </LinkCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </QuickLinksSection>

      {/* Charts and Graphs Section */}
      <ChartsSection>
        <h3>Statistics</h3>
        <ChartsGrid>
          {/* Line Chart for Revenue Over Time */}
          <ChartContainer>
            <h4>{chartData.lineChart.title}</h4>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={chartData.lineChart.data}>
                <XAxis dataKey={chartData.lineChart.xKey} />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey={chartData.lineChart.yKey} stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>

          {/* Pie Chart for Subscription Types */}
          <ChartContainer>
            <h4>{chartData.pieChart.title}</h4>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={chartData.pieChart.data}
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label
                >
                  {chartData.pieChart.data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={chartData.pieChart.colors[index % chartData.pieChart.colors.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </ChartContainer>
        </ChartsGrid>
      </ChartsSection>

      {/* Promotions Section */}
      <PromotionsSection>
        <h3>Latest Promotions</h3>
        <Promotions>
          {promotions.map((promo, index) => (
            <PromoCard key={index}>
              <h4>{promo.title}</h4>
              <p>{promo.description}</p>
              <Button variant="contained" color="primary">{promo.buttonText}</Button>
            </PromoCard>
          ))}
        </Promotions>
      </PromotionsSection>
    </Container>
  );
};

// ======================= Styled Components =======================

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const WelcomeSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextSection = styled.div`
  flex: 1;
  & h2 {
    font-size: 2.5rem;
  }
  & p {
    font-size: 1.2rem;
    color: #666;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const HeaderImage = styled.img`
  max-width: 100%;
  height: 200px;
  margin-top: 20px;
  border-radius: 10px;
`;

const QuickLinksSection = styled.section`
  padding: 40px 0;
  & h3 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const LinkCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  & h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  & p {
    font-size: 1rem;
    color: #777;
    margin-bottom: 10px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #007bff;
  &:hover {
    text-decoration: underline;
  }
`;

const ChartsSection = styled.section`
  padding: 40px 0;
  & h3 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const ChartsGrid = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const ChartContainer = styled.div`
  width: 45%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PromotionsSection = styled.section`
  padding: 40px 0;
  background-color: #f7f7f7;
  & h3 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Promotions = styled.div`
  display: flex;
  justify-content: space-around;
`;

const PromoCard = styled.div`
  width: 45%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  & h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  & p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
  }
`;

export default Dashboard;
