import { Business as BusinessIcon } from '@mui/icons-material';
import Dashboard from './dashboard/Dashboard';

// Sidebar configuration
export const sidebarConfig = [
  { key: 'home', label: 'Dashboard', route: '/Home', icon: <BusinessIcon /> }
];

// App routes configuration
export const appRoutes = [
  {
    key: 'root',
    path: '/',
    component: Dashboard,
  },
  {
    key: 'home',
    path: '/Home',
    component: Dashboard,
  },
];
