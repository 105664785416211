import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, TextField, Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react'; // Import Auth0 hook
import { FaCog, FaIndustry, FaTruck, FaCheckCircle, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'; // Import icons
import { headerData, heroData, servicesData, aboutData, contactData, footerData } from './LandingData';
import styled from '@emotion/styled';

import {
  HeaderContainer, Logo, RightPanel, SectionContainer,
  SectionTitle, SectionDescription, CardContainer, Card, CardIcon,
  Footer, FooterLink, FooterColumn, ContactSection, ContactCard, ContactIcon, ContactTitle, ContactInfo, ContactButton, FormGroup
} from '../CommonStyles'; // Ensure FormGroup is imported here

import AOS from 'aos';
import 'aos/dist/aos.css';

function Landing() {
  const { loginWithRedirect } = useAuth0();
  const [newTenantName, setNewTenantName] = useState('');
  const [formError, setFormError] = useState(null);

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Animation duration
      easing: 'ease-in-out',  // Easing function for animations
      once: true,  // Whether animation should happen only once while scrolling
    });
  }, []);

  const handleBlur = () => {
    if (!newTenantName) {
      setFormError('tenantName');
    } else if (newTenantName.length < 3) {
      setFormError('tenantNameLength');
    } else {
      setFormError(null);
    }
  };

  return (
    <Box>
      {/* Header */}
      <HeaderContainer>
        <Logo>
          <Typography variant="h5" component="h1">{headerData.logo}</Typography>
        </Logo>
        <RightPanel>
          <nav>
            <ul>
              {headerData.links.map((link, index) => (
                <li key={index}><a href={link.href}>{link.label}</a></li>
              ))}
            </ul>
          </nav>
          <Box ml={2}>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'primary', color: '#fff', '&:hover': { backgroundColor: '#FF8C00' } }}
              onClick={() => loginWithRedirect({ redirectUri: `${window.location.origin}/Home` })}
            >
              Login
            </Button>
          </Box>
        </RightPanel>
      </HeaderContainer>

      {/* Hero Section */}
      <SectionContainer
        sx={{
          backgroundImage: `url('/hero-image.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          color: 'white',
          padding: '20px',
        }}
        id="hero"
      >
        <TextContainer
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black background with 50% transparency
            padding: '20px',
            borderRadius: '10px',
            display: 'inline-block',
          }}
        >
          <h1>{heroData.title}</h1>
          <p>
            {heroData.description}
          </p>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.6)', // White button with 60% transparency
              color: 'white',
              marginTop: '10px',
            }}
          >
            Get Started
          </Button>
        </TextContainer>
      </SectionContainer>

      {/* Services Section */}
      <SectionContainer id="services">
        <Container>
          <SectionTitle data-aos="fade-up">{servicesData.title}</SectionTitle>
          <CardContainer>
            {servicesData.services.map((service, index) => {
              const Icon = service.icon === 'FaCog' ? FaCog : service.icon === 'FaIndustry' ? FaIndustry : service.icon === 'FaTruck' ? FaTruck : FaCheckCircle;
              return (
                <Card key={index} data-aos="zoom-in">
                  <CardIcon>
                    <Icon />
                  </CardIcon>
                  <Typography variant="h5">{service.title}</Typography>
                  <Typography variant="body2">{service.description}</Typography>
                </Card>
              );
            })}
          </CardContainer>
        </Container>
      </SectionContainer>

      {/* About Section */}
      <SectionContainer id="about">
        <Container>
          <SectionTitle data-aos="fade-up">{aboutData.title}</SectionTitle>
          <SectionDescription data-aos="fade-up">{aboutData.description}</SectionDescription>
        </Container>
      </SectionContainer>

      {/* Contact Us Section */}
      <SectionContainer id="contact" sx={{ backgroundColor: '#f5f5f5', padding: '4em 0' }}>
        <Container>
          <Typography variant="h4" gutterBottom textAlign="center">Contact Us</Typography>

          <ContactSection sx={{ gap: '15px' }}>
            {/* Contact Form */}
            <ContactCard data-aos="fade-up" sx={{ padding: '1.5em', height: 'auto', maxHeight: '500px' }}>
              <ContactIcon>
                <FaEnvelope />
              </ContactIcon>
              <ContactTitle>Get in Touch</ContactTitle>
              {contactData.formFields.map((field, index) => (
                <FormGroup key={index}>
                  <TextField
                    required
                    margin="dense"
                    label={field.label}
                    variant="outlined"
                    value={newTenantName}
                    onChange={(e) => setNewTenantName(e.target.value)}
                    onBlur={handleBlur}
                    fullWidth
                  />
                </FormGroup>
              ))}
              <ContactButton variant="contained" type="submit">{contactData.buttonText}</ContactButton>
            </ContactCard>

            {/* Contact Information Card */}
            <ContactCard data-aos="fade-up" sx={{ padding: '1.5em', height: 'auto', minHeight: '300px' }}>
              <ContactIcon>
                <FaMapMarkerAlt />
              </ContactIcon>
              <ContactTitle>Contact Information</ContactTitle>
              <ContactInfo>1234 Example St, Suite 100</ContactInfo>
              <ContactInfo>City, State, 12345</ContactInfo>
              <ContactInfo>Email: info@example.com</ContactInfo>
              <ContactInfo>Phone: (123) 456-7890</ContactInfo>
            </ContactCard>
          </ContactSection>
        </Container>
      </SectionContainer>

      {/* Footer */}
      <Footer>
        {Object.keys(footerData.columns).map((columnKey) => {
          const column = footerData.columns[columnKey];
          return (
            <FooterColumn key={columnKey}>
              <Typography variant="h6">{column.title}</Typography>
              {column.links.map((link, index) => (
                <FooterLink key={index} href={link.href}>
                  {link.label}
                </FooterLink>
              ))}
            </FooterColumn>
          );
        })}
      </Footer>

      <Box textAlign="center" py={2}>
        <Typography variant="body2">{footerData.copyright}</Typography>
      </Box>
    </Box>
  );
}

// TextContainer styles for better readability
const TextContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.6); // Slightly darker background
  color: white;
  padding: 20px 40px; // Increase padding for better spacing
  border-radius: 10px; // Rounded corners for smoother design
  text-align: center;
  max-width: 800px;
  margin: 0 auto; // Center the text container

  & h1 {
    font-size: 3rem; // Larger font size for emphasis
    font-weight: bold; // Increase weight for better visibility
  }

  & p {
    font-size: 1.25rem; // Slightly larger subtitle
    margin-top: 10px; // Space between the title and subtitle
  }

  @media (max-width: 768px) {
    padding: 15px 20px; // Adjust padding for mobile responsiveness
    & h1 {
      font-size: 2.5rem; // Responsive font size on smaller screens
    }
    & p {
      font-size: 1rem; // Adjust subtitle size for smaller screens
    }
  }
`;


export default Landing;
