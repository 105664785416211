// MainLayout.js
import React, { useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { sidebarConfig, appRoutes } from './Routes';
import Header from './header/Header';
import { KeyboardDoubleArrowLeftOutlined, KeyboardDoubleArrowRightOutlined } from '@mui/icons-material';

const MainLayout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const collapseSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMenuItemClick = (item) => {
    navigate(item.route);
  };

  const renderMenuItems = (items) => {
    return items.map((item) => {
      const isActive = location.pathname === item.route;
      return (
        <StyledMenuItem
          key={item.key}
          active={isActive}
          isCollapsed={isCollapsed}
          onClick={() => handleMenuItemClick(item)}
        >
          <IconStyled isCollapsed={isCollapsed}>{item.icon}</IconStyled>
          {!isCollapsed && <span>{item.label}</span>}
        </StyledMenuItem>
      );
    });
  };

  return (
    <Container>
      <Header />

      <Layout>
        <SidebarContainer isCollapsed={isCollapsed}>
          <MenuItemsContainer>
            {renderMenuItems(sidebarConfig)}
          </MenuItemsContainer>
          <CollapseItems onClick={collapseSidebar}>
            {!isCollapsed ? (
              <KeyboardDoubleArrowLeftOutlined color="primary" />
            ) : (
              <KeyboardDoubleArrowRightOutlined color="primary" />
            )}
          </CollapseItems>
        </SidebarContainer>

        <MainContainer>
          <Routes>
            {appRoutes.map((route) => (
              <Route key={route.key} path={route.path} element={<route.component />} />
            ))}
          </Routes>
        </MainContainer>
      </Layout>
    </Container>
  );
};

// ======================= Styled Components =======================

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Layout = styled.div`
  display: flex;
  height: calc(100% - 64px); /* Subtract header height */
  overflow: hidden;
`;

const SidebarContainer = styled.div`
  width: ${({ isCollapsed }) => (isCollapsed ? '80px' : '240px')};
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
  height: 100%;
`;

const MenuItemsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const StyledMenuItem = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#f0f0f0' : 'transparent')};
  margin: 10px 10px 0;
  border-radius: 10px;
  &:hover {
    background-color: #fafafa;
  }
`;

const IconStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isCollapsed }) => (isCollapsed ? '100%' : '40px')};
`;

const CollapseItems = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  cursor: pointer;
  border-top: 1px solid #ddd;
`;

const MainContainer = styled.div`
  flex: 1;
  padding: 24px;
  overflow-y: auto;
`;

export default MainLayout;
