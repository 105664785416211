import { Box, Button, Typography, TextField } from '@mui/material';
import styled from '@emotion/styled';

// ======================= Header Styles =======================
export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  padding: 16px 24px;
  color: #ffffff;
`;

export const Logo = styled(Box)`
  display: flex;
  cursor: pointer;
`;

export const RightPanel = styled(Box)`
  display: flex;
  align-items: center;

  nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1.5em;
  }

  nav ul li {
    display: inline-block;
  }

  nav ul li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1em;  // Adjusted font size
    transition: color 0.3s;

    &:hover {
      color: #00bfff;
    }
  }
`;

// ======================= Section Styles =======================
export const SectionContainer = styled(Box)`
  padding: 6em 0;
  background-color: ${({ bgColor }) => bgColor || '#f5f5f5'};
  text-align: center;
`;

export const SectionContent = styled(Box)`
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
`;

export const SectionTitle = styled(Typography)`
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 1em;
`;

export const SectionDescription = styled(Typography)`
  font-size: 1.2em;
  color: #666666;
  margin-bottom: 2em;
`;

// ======================= Button Styles =======================
export const CTAButton = styled(Button)`
  background-color: #ffffff;
  color: #0000ff;
  &:hover {
    background-color: #0000ff;
    color: #ffffff;
  }
`;

// ======================= Utility Styles =======================
export const SpaceBottom = styled(Box)`
  margin-top: ${({ top }) => (top ? `${top}px` : '10px')};
`;

// ======================= Card Styles =======================
export const CardContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Card = styled(Box)`
  flex: 1 1 calc(30% - 20px);
  background-color: #ffffff;
  padding: 3em;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  min-width: 250px;
  max-width: 350px;

  &:hover {
    transform: translateY(-10px);
  }
`;

export const CardIcon = styled(Box)`
  font-size: 2em;
  color: #0000ff;
  margin-bottom: 1em;
`;

// ======================= Contact Us Section =======================
export const ContactSection = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 4em 0;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    padding: 2em 1em;
  }
`;

export const ContactCard = styled(Box)`
  background-color: #ffffff;
  padding: 2em;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const ContactIcon = styled(Box)`
  font-size: 3em;
  margin-bottom: 1em;
  color: #ff8c00;
`;

export const ContactTitle = styled(Typography)`
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 1em;
`;

export const ContactInfo = styled(Typography)`
  font-size: 1.1em;
  color: #666666;
  margin-bottom: 0.5em;
`;

export const ContactButton = styled(Button)`
  margin-top: 1em;
  background-color: #000000;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 1.1em;
  border-radius: 12px;
  text-transform: none;

  &:hover {
    background-color: #333333;
  }
`;

// ======================= Footer Styles =======================
export const Footer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 3em 0;
  background-color: #333333;
  color: #ffffff;
`;

export const FooterColumn = styled(Box)`
  flex: 1;
  padding: 0 1em;
`;

export const FooterLink = styled(Typography)`
  color: #00bfff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

// ======================= Form Styles =======================
export const FormGroup = styled(Box)`
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
`;

