export const convertHexToRGBA = (hexCode, opacity = 1) => {
   let hex = hexCode.replace('#', '')

   if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
   }

   const r = parseInt(hex.substring(0, 2), 16)
   const g = parseInt(hex.substring(2, 4), 16)
   const b = parseInt(hex.substring(4, 6), 16)

   if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100
   }

   return `rgba(${r},${g},${b},${opacity})`
}

export const theme = {
   colors: {
      text: '#000000',
      primary: '#0000ff',
      secondary: '#a9a9a9',
      hover: '#f0f0f0',
      activeBackground: '#e0e0e0',
      searchBackground: '#d3d3d3',
      profileBackground: '#a9a9a9',
      tutorialBackground: '#F5F5F5',
      sidebarIconColor: '#789',
      font1: '#444444',
      font2: '#6C6C6C',
      iconGrey: '00000096',
      white: '#ffffff',
      blue: '#3f59e4',
      green: '#5CB85C',
      red: '#DC3545',
      lightGreen: '#5cb85c3b',
      dark: '#000000',
      black: '#212121',
      grey: '#424242',
      lightGrey: '#61616169',
      primary1: '#3f59e4',
      boxBorder: 'rgba(0, 0, 0, 0.20)',
   },
   mui: {
      palette: {
         primary: {
            main: '#000000',
         },
         secondary: {
            main: '#5CB85C',
         },
         info: {
            main: '#6C6C6C',
         },
         error: {
            main: '#DC3545',
         },
         success: {
            main: '#2e7d3280',
         },
      },
   },
   typography: {
      fontFamily: "'Arial', sans-serif",
      fontSize: 14,
      h1: {
         fontSize: '2rem',
         fontWeight: 700,
      },
      h2: {
         fontSize: '1.75rem',
         fontWeight: 600,
      },
      h3: {
         fontSize: '1.5rem',
         fontWeight: 500,
      },
      body1: {
         fontSize: '1rem',
         fontWeight: 400,
      },
   },
   shadows: {
      default: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      strong: '0px 6px 12px rgba(0, 0, 0, 0.2)',
      soft: '0px 2px 5px rgba(0, 0, 0, 0.05)',
   },
   spacing: {
      xs: '4px',
      sm: '8px',
      md: '16px',
      lg: '24px',
      xl: '32px',
   },
   breakpoints: {
      xs: '0px',
      sm: '600px',
      md: '960px',
      lg: '1280px',
      xl: '1920px',
   },
   buttonVariants: {
      primary: {
         backgroundColor: '#0000ff',
         color: '#ffffff',
         '&:hover': {
            backgroundColor: '#3f59e4',
         },
      },
      secondary: {
         backgroundColor: '#a9a9a9',
         color: '#ffffff',
         '&:hover': {
            backgroundColor: '#f0f0f0',
         },
      },
      outlined: {
         border: `1px solid #0000ff`,
         color: '#0000ff',
         '&:hover': {
            backgroundColor: convertHexToRGBA('#0000ff', 0.1),
         },
      },
   },
}

export function addOpacity(color, opacity) {
   const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255)
   return color + _opacity.toString(16).toUpperCase()
}
